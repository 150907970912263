import { useRef, useEffect } from 'react'
import Image from 'next/image'
import { useTranslations } from 'next-intl'
import { TextSearchInput } from '@/app/components/TextSearchInput'
import { useAuth } from '@/app/providers/Auth/Auth'
import { useSearchInputContext } from '@/app/modules/course/contexts/InputSearchContext'
import { useIntersectionObserver } from '@/app/modules/course/hooks/useIntersectionObserver'

type CoursesHeaderProps = {
  openModal: () => void
  onSearch: (searchTerm: string) => void
  wrapperProps?: React.HTMLProps<HTMLDivElement>
} & React.HTMLProps<HTMLDivElement>

export const CoursesHeader = ({
  wrapperProps,
  onSearch,
  ...props
}: CoursesHeaderProps) => {
  const { firstName } = useAuth()
  const t = useTranslations('Courses')
  const searchInputRef = useRef<HTMLDivElement>(null)
  const isVisible = useIntersectionObserver(searchInputRef)
  const { setIsLargeSearchVisible } = useSearchInputContext()

  useEffect(() => {
    setIsLargeSearchVisible(isVisible)
  }, [isVisible, setIsLargeSearchVisible])

  return (
    <div
      {...wrapperProps}
      className={`bg-gradient-to-b from-white to-[#FFF9F1] ${wrapperProps?.className ?? ''}`}
    >
      <div
        {...props}
        className={`py-12 sm:py-20 flex flex-col gap-10 ${props.className ?? ''}`}
      >
        <div className="text-3xl md:text-4xl font-title">
          {t('welcome', { name: firstName })} 👋🏼
        </div>
        <div className="text-base md:text-lg">{t('intro_message')}</div>
        <div className="grid grid-rows-1 gap-7">
          <div
            className="flex flex-row items-center gap-5 p-8 bg-mydra-light-purple rounded-xl md:h-[383px]"
            style={{ backgroundImage: 'url(/images/search-bg.png)' }}
          >
            <div className="flex flex-col gap-10 flex-[5]">
              <div className="text-2xl md:text-4xl font-title text-center">
                {t('text_search_title')}
              </div>

              <div
                ref={searchInputRef}
                className="w-full xs:max-w-[480px] sm:max-w-[580px] mx-auto"
              >
                <TextSearchInput onSearch={onSearch} />
              </div>

              <div className=" hidden md:flex flex-row flex-wrap justify-center items-center gap-2 md:gap-8">
                <Image
                  src="/images/henry-lg.png"
                  alt="Henry"
                  width={96}
                  height={45}
                  className="w-[38px] sm:w-[48px] md:w-[96px] h-auto object-contain"
                  priority
                />
                <Image
                  src="/images/hult-lg.png"
                  alt="Hult"
                  width={96}
                  height={45}
                  className="w-[38px] sm:w-[48px] md:w-[96px] h-auto object-contain"
                  priority
                />
                <Image
                  src="/images/IE-university-lg.png"
                  alt="IE University"
                  width={79}
                  height={47}
                  className="w-[30px] sm:w-[40px] md:w-[79px] h-auto object-contain"
                  priority
                />
                <Image
                  src="/images/integra-lg.png"
                  alt="Integra"
                  width={118}
                  height={72}
                  className="w-[49px] sm:w-[59px] md:w-[118px] h-auto object-contain"
                  priority
                />
                <Image
                  src="/images/iebs.png"
                  alt="IEBS"
                  width={118}
                  height={72}
                  className="w-[49px] sm:w-[59px] md:w-[118px] h-auto object-contain"
                  priority
                />
                <Image
                  src="/images/CEI-lg.png"
                  alt="CEI"
                  width={147}
                  height={93}
                  className="w-[25px] sm:w-[35px] md:w-[147px] h-auto object-contain"
                  priority
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
